import React from "react";
import { graphql } from "gatsby";

import Layout from "../../../shared/src/components/layout";
import Head from "../../../shared/src/components/head";

import * as styles from "./index.module.css";

const WwwIndex = ({ data, location }) => (
  <Layout location={location} menu={data.site.siteMetadata.menu} title="MN">
    <Head />
    <div className={styles.hero}>
      <h1>
        Matthew
        <br />
        Sojourner
        <br />
        Newton
      </h1>
      <h2>
        IT Consultant, &c.
        <br />
        San Francisco
      </h2>
      <h4>
        <a href="mailto:matt@mnewton.com">matt@mnewton.com</a>
      </h4>
    </div>
  </Layout>
);

export default WwwIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        menu
      }
    }
  }
`;
